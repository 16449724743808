var site = site || {};
$(document).ready(function () {
  $(window).on('window:show_afterpay_overlay', function() {
    var content = (site.client.isMobile == 1) ? $('.afterpay-content-mb') : $('.afterpay-content');
    if (content.length > 0) {
      generic.overlay.launch({
        content: content.clone(true),
        includeBackground: true,
        includeCloseLink: true,
        initialHeight: 0,
        height: 0,
        width: '55%',
      });
    }
  });

  $('body').on('click', '#ap-learnmore', function() {
    $(window).trigger('window:show_afterpay_overlay');
      return false;
  });
  var handleAfterPayPrice = function (){
    var sku_id = $('select.js-sku-menu').val();
    $('.afterpay_sku').hide();
    $('.afterpay_sku[data-sku_base_id="'+sku_id+'"]').show();
  };  
  handleAfterPayPrice();
  $('body').on('change', 'select.js-sku-menu', function() {
    handleAfterPayPrice();
  });
});